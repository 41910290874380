import React from 'react'
import { PrismicRichText } from '@prismicio/react'
import { linkResolver } from '../../utils/linkResolver'
import { Link } from 'gatsby'


    const TextBlock = ({data}) =>{

        return (
            <section className="TextBlock">

                <PrismicRichText 
                field={data.primary.text.richText}
                components={{
                    hyperlink: ({ node, children }) => {
                       const link = linkResolver(node.data)
                    return(
                        <Link to={link} >{children}</Link> 
                 
                    )
                    
                   
                }
                }}
                />
            </section>
        )
    } 

export default TextBlock;