import React from 'react';
import TextBlock from './TextBlock/TextBlock'
import ImageAndText from './ImageAndText/ImageAndText'
import FiftyFifty from './FiftyFifty/FiftyFifty';
import ThreeIcon from './ThreeIcon/ThreeIcon'

const ComponentSwitch = ({ components }) => {
  const component = components.map(s => {
    switch (s.slice_type) {
      case 'text':
        return <TextBlock key={s.id} data={s} />
      case 'hero_image_and_text':
        return <ImageAndText key={s.id} data={s} />
      case 'fifty_fifty':
        return <FiftyFifty key={s.id} data={s} />
      case 'three_icon_':
        return <ThreeIcon key={s.id} data={s} />
      default:
        return null
    }
  })
  return <div className="ContentComponent">{component}</div>
}


export default ComponentSwitch;



