import React from 'react'
import Picture from '../common-components/Picture'
    const ThreeIcon = ({data}) => (
        <section className="ThreeIcon">
            <h2>{data.primary.title1}</h2>
            <div className="ThreeIconsIcons">
            {data && data.items.map((item, n) => (
                <div className="ThreeIconsIconsItem">
                       <Picture fallback={item.image.url} alt={item.image.alt} className="ThreeIconsIconsItemImage" />
                       <h4>{item.copy}</h4>
                </div>
            ))}
            </div>
            

        </section>
        
    )

export default ThreeIcon;