import React from 'react'
import Picture from '../common-components/Picture';

    const ImageAndText = ({data}) => (
        <section className="ImageAndText">
                <h1>{data.primary.title1}</h1>
                <Picture sharp={data.primary.image.fluid} fallback={data.primary.image.url} alt={data.primary.image.alt} className="ImageAndTextImage"/>
        </section>
        
    )

export default ImageAndText;