import React from 'react'
import Picture from '../common-components/Picture';
import { PrismicRichText } from '@prismicio/react'
import LinkWrap from '../common-components/LinkWrap'

const FiftyFifty = ({ data }) => {

    var Style={
        backgroundColor: data.primary.background_colour
    };

    return(
    <section className="FiftyFifty">
        <div className="FiftyFiftyText" style={Style}>
            <Picture fallback={data.primary.icon.url} alt={data.primary.icon.alt} className="FiftyFiftyTextIcon" />
            <h3>{data.primary.title1}</h3>
            <div className="FiftyFiftyTextCopy">
                <PrismicRichText field={data.primary.copy.richText}  />
            </div>
        </div>
        

        <div className="FiftyFiftyImage">
            <div className="FiftyFiftyImageButton"><LinkWrap link={data.primary.button_link} >{data.primary.button_text}</LinkWrap></div>
            <Picture sharp={data.primary.imageSharp} fallback={data.primary.image.url} alt={data.primary.image.alt} className="FiftyFiftyTextImageInner" />
        </div>
    </section>
    )
}

export default FiftyFifty;