import React from 'react'
import Img from 'gatsby-image'
import PropTypes from 'prop-types'

const Picture = (props) => {
    return(
        <img draggable="false" itemProp="image" src={props.fallback} alt={props.alt} className={props.className} />
)}

export default Picture

Picture.propTypes = {
    fluid: PropTypes.object,
    alt: PropTypes.string,
}
